@layer base {
  /* stylelint-disable plugin/selector-bem-pattern */
/** @define Loading */

.sha-Loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: auto;
}

.sha-Loading__spinner--isOnWhiteBackground {
  stop-color: var(--mainColor);
}

}