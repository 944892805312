@layer base {
  .main {
  display: block;
  /* Override the main html element in iframe with padding */
  width: calc(100% + 20px);
  /* Override the main html element in iframe with padding */
  margin: 0 -20px;
  border: none;
}

.mainLoading {
  display: none;
}

.loader {
  height: 100vh;
}

@media (--large-viewport) {
  /* Override the main html element in iframe with padding */
  .main {
    width: calc(100% + 40px);
  }
}

}