@layer base {
  .clickableCard {
  border-color: transparent !important;
}

.uploadButton {
  width: auto;
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: var(--sk-space-16);
}

.footer input[type="file"] {
  display: none;
}

}