@layer base {
  .goBack {
  align-self: flex-start;
}

.main {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-40);
  padding: 0 var(--sk-space-16);
}

.blockButton {
  display: flex;
  flex-direction: column;
}

@media (--sk-medium-viewport) {
  .main {
    padding: 0;
  }

  .blockButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

}