@layer base {
  .goBack {
  align-self: flex-start;
}

.main {
  display: flex;
  flex-direction: column;
}

.aside {
  display: none;
}

.title {
  margin-bottom: var(--sk-space-16);
  padding: var(--sk-space-24) var(--sk-space-16);
  background-color: var(--sk-color-black);
  color: var(--sk-color-white);
}

.moduleSection {
  padding: var(--sk-space-32) var(--sk-space-16);
  background-color: var(--sk-color-white);
}

.unitHeader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: var(--sk-space-24);
  border-bottom: thin solid var(--sk-color-grey-200);
  gap: var(--sk-space-8);
}

.stepper {
  display: flex;
  gap: var(--sk-space-8);
}

.stepperItem {
  width: 20px;
  height: 20px;
  color: var(--sk-color-grey-500);
}

.stepperItemIcon {
  width: 20px;
  height: 20px;
  color: var(--sk-color-black);
}

.unitNavigation {
  display: flex;
  justify-content: space-between;
  margin-top: var(--sk-space-64);
  gap: var(--sk-space-40);
}

.unitCompletion {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--sk-space-8);
  justify-content: center;
  margin-top: var(--sk-space-64);
}

.completionQuizzTip {
  display: flex;
  color: var(--sk-color-warning-500);
  text-align: center;
  gap: var(--sk-space-4);
}

@media (--sk-medium-viewport) {
  .title {
    padding: var(--sk-space-32) var(--sk-space-40);
    border-radius: var(--sk-radius-16);
  }

  .moduleSection {
    padding: var(--sk-space-32) var(--sk-space-72);
    border: thin solid var(--sk-color-grey-100);
    border-radius: var(--sk-radius-16);
  }

  .unitHeader {
    flex-direction: row-reverse;
    align-items: center;
  }
}

@media (--sk-large-viewport) {
  .main {
    flex-direction: row;
    gap: var(--sk-space-8);
  }

  .content {
    flex: 1;
    width: calc(100vw - 496px);
  }

  /* Aside Begin */

  .aside {
    display: block;
    width: 312px;
    padding: var(--sk-space-24);
  }

  .asideHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--sk-space-16);
    gap: var(--sk-space-24);
  }

  .asideProgressBar {
    width: 100%;
    height: var(--sk-space-4);
    margin-bottom: var(--sk-space-40);
    border-radius: var(--sk-radius-12);
    background-color: var(--sk-color-grey-100);
  }

  .asideProgress {
    height: 100%;
    background-color: var(--sk-color-black);
  }

  .sequencesList {
    display: flex;
    flex-direction: column;
    gap: var(--sk-space-20);
    padding: 0;
    list-style-type: none;
  }

  .sequenceItemLink {
    display: flex;
    gap: var(--sk-space-8);
    color: var(--sk-color-black);
    font-size: var(--sk-typography-body-2-regular-font-size);
    text-decoration: none;
  }

  .sequenceItemLinkComplete {
    color: var(--sk-color-grey-600);
  }

  .sequenceItemLinkCurrent {
    color: var(--sk-color-purple-500);
    font-weight: var(--sk-typography-body-2-semibold-font-weight);
  }

  .sequenceItemLinkDefault {
    margin-left: calc(var(--sk-space-24) + var(--sk-space-8));
  }

  .sequenceItemTitle {
    flex: 1;
  }

  /* Aside End */
}

}