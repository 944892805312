@layer base {
  .header {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-8);
  padding: var(--sk-space-40) 0;
  text-align: center;
}

.content {
  padding-bottom: var(--sk-space-40);
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: var(--sk-space-24);
}

.footer__button {
  flex: 1 1 auto;
}

.footer input[type="file"] {
  display: none;
}

@media (--sk-small-viewport-only) {
  .footer {
    display: flex;
    position: absolute;
    right: var(--sk-space-16);
    bottom: var(--sk-space-40);
    left: var(--sk-space-16);
    flex-direction: column;
    gap: var(--sk-space-16);
  }
}

.cvHelpText {
  margin-top: var(--sk-space-16); 
  color: var(--sk-color-grey-600);
}

}