@layer base {
  .messageField {
  box-sizing: border-box;
  width: 100%;
  padding: var(--sk-space-12) var(--sk-space-4) var(--sk-space-12) var(--sk-space-16);
  border: 1px solid var(--sk-color-grey-300);
  border-radius: var(--sk-radius-8);
  font-family: var(--sk-font-families-poppins);
  font-size: var(--sk-font-size-2);
  resize: vertical;
}

.messageField:focus {
  outline: 2px solid var(--sk-color-grey-500);
  outline-offset: -1px;
}

.messageLabel {
  margin: var(--sk-space-16) 0 var(--sk-space-4);
}

.messageLength {
  color: var(--sk-color-grey-500);
  text-align: end;
}

.error {
  margin-top: var(--sk-space-8);
  color: var(--sk-color-error-500);
}

.warning {
  margin-top: var(--sk-space-8);
  color: var(--sk-color-warning-500);
}

}