@layer base {
  /** @define Error */
/* stylelint-disable plugin/selector-bem-pattern */

.wrapper {
  box-sizing: border-box;
  min-height: 100vh;
  padding: 3em;
}

.main {
  display: flex;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  padding: var(--sk-space-32);
  border: 2px solid var(--sk-color-black);
  border-radius: var(--sk-space-8);
}

.content {
  display: flex;
  align-items: center;
  margin-bottom: var(--sk-space-32);
}

.illustration {
  align-self: center;
  width: 100%;
  max-width: 300px;
}

.illustration img {
  width: 100%;
  height: auto;
}

.errorId {
  font-style: italic;
  text-align: right;
}

@media (--medium-viewport) {
  .main {
    flex-direction: row;
  }

  .content {
    flex: 3 1 0;
    margin-right: var(--sk-space-32);
    margin-bottom: 0;
  }

  .illustration img {
    flex: 2 1 0;
    width: auto;
  }

  .errorId {
    position: absolute;
    right: 1rem;
    bottom: 0.5rem;
  }
}

@media (--large-viewport) {
  .main {
    max-width: 75%;
    margin: 0 auto;
  }
}

}