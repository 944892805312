@layer base {
  .main {
  --form-transform: translateX(0);

  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--sk-color-white);
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: var(--sk-space-24) var(--sk-space-24) 0;
}

.isLoading {
  pointer-events: none;
}

.closeButton {
  margin-left: auto;
}

.content {
  flex-grow: 1; /* Take all the remaining height */
  padding: var(--sk-space-24) var(--sk-space-24) 0;
  overflow: hidden auto; /* Make vertical content scrollable */
}

/**
 * The layout make twice the width of the container and translate it to the left.
 */
.content--twoCols {
  display: grid;
  box-sizing: border-box;
  grid-template-columns: 1fr 1fr;
  width: 200%;
  transform: var(--form-transform);
  transition: transform 300ms ease-in-out;
  gap: var(--sk-space-48);
}

.footer {
  padding: var(--sk-space-24);
  background-color: var(--sk-color-white);
  /* shadow is --sk-color-grey-600 with 15% opacity */
  box-shadow: 0 var(--sk-space-4) var(--sk-space-16) rgb(100 96 108 / 15%);
}

.button {
  width: 100%;
}

.form {
  height: fit-content;
}

.form--hidden {
  visibility: hidden;
  pointer-events: none;
}

@media (--sk-large-viewport) {
  .main {
    position: sticky;
    height: calc(100vh - var(--distance-to-top, 0) - var(--sk-space-24) * 2);
    margin: var(--sk-space-24) 0;
    overflow: hidden auto;
    transition: height 0.2s;
    border-radius: var(--sk-radius-24);
    box-shadow: 0 var(--sk-space-2) var(--sk-space-24) 0 rgb(0 0 0 / 16%);
    inset: var(--sk-space-24) 0;
  }

  .heading___success {
    display: none;
  }
}

}