@layer base {
  .container {
  display: none;
}

.container___visible {
  display: block;
}

.main {
  display: none;
  position: relative;
  padding: var(--sk-space-24);
  border-radius: var(--sk-radius-16);
  background-color: var(--sk-color-grey-050);
  text-align: center;
}

.main___visible {
  display: block;
}

.main___dragOver {
  background-color: var(--sk-color-grey-100);
}

.overtitle {
  margin-bottom: var(--sk-space-16);
}

.icon {
  margin-bottom: var(--sk-space-24);
  background-color: var(--sk-color-grey-100);
}

.fileInfo {
  margin-top: var(--sk-space-24);
  color: var(--sk-color-grey-500);
}

.description {
  margin-top: var(--sk-space-4);
}

.uploadButton {
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context);
  width: 100%;
  margin-top: var(--sk-space-16);
}

/* This prevents to drop a file on the button */
.main___dragOver .uploadButton {
  z-index: var(--z-reset-in-the-current-stacking-context);
}

/* The input needs to be above the whole component to accept dropped files */
.uploadButton__input {
  position: absolute;
  z-index: var(--z-reset-in-the-current-stacking-context);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.error {
  margin-top: var(--sk-space-8);
  color: var(--sk-color-error-500);
}

}